<template>
  <div>
    <table class="custom-table">
      <tbody>
        <tr
          v-for="(job, index) in bulkJobForms[moduleName]"
          :key="job.key"
        >
          <td>
            {{ job.company_name }}
          </td>
          <td>
            {{ job.position }}
          </td>
          <td>
            <b-form-group class="m-0 p-0" :disabled="job.event_status === 'draft'">
              <div class="d-flex flex-row mx-1 mt-2">
                
                <b-form-radio
                  v-model="job.publication_status"
                  :name="`${job.local_storage_id}-publication_status-radios`"
                  value="draft"
                ><p class="mb-1 mr-2">Draft</p></b-form-radio>
                <b-form-radio
                  v-model="job.publication_status"
                  :name="`${job.local_storage_id}-publication_status-radios`"
                  value="live"
                ><p class="mb-1 mr-2">Publish</p></b-form-radio>
              </div>
            </b-form-group>
          </td>
          <td>
            <v-select
              v-if="$isNotEmpty(job.record_action_options)"
              v-model="job.record_action"
              placeholder="Choose an action"
              :options="job.record_action_options"
              label="text"
              class="w-full"
              :reduce="value => value.value"
              @input="updateBulkJobForms(bulkJobForms)"
            />
          </td>
          <td>
            <b-button
              variant="danger"
              @click="() => {
                deleteBulkJobForm(moduleName, index)
                bulkJobForms = loadBulkJobForms()
              }"
            >Delete</b-button>
          </td>
           
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { bulkUploadFormMixin } from './mixins/bulkUploadFormMixins'

export default {
  components: {
    vSelect,
  },

  mixins: [
    bulkUploadFormMixin,
  ],

  props: {
    moduleName: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      bulkJobForms: {},
      actionOptions: [
        { text: 'test', value: 'test' },
      ],
    }
  },

  created() {
    this.bulkJobForms = this.loadBulkJobForms()
  },

  methods: {
    updateJob() {
      //
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.custom-table {
  width: 100%;

  thead {
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}

</style>